<template>
  <div>
    <d-table
      class="my-4"
      :headers="headers"
      :items="data || []"
      compact
      rowClasses="cursor-pointer"
      :rowClick="gotoBoard"
      wrapperClasses="min-h-screen overflow-y-scroll"
    />
  </div>
</template>

<script>
import _headers from './headers'
import { gmxActions } from '@/store/constants.js'
import { mapState } from 'vuex'

export default {
  name: 'gmx-board',

  meta: {
    title: 'GMX Board',
  },

  computed: {
    ...mapState({
      data: (state) => state.gmx.data,
    }),
    headers() {
      return _headers(this)
    },
  },

  methods: {
    gotoBoard(event, row) {
      const url = `https://www.gmx.house/arbitrum/account/${row.account}`
      window.open(url, '_blank')
    },
  },

  mounted() {
    this.$store.dispatch(`gmx/${gmxActions.LOAD_GMX_BOARD_DATA}`)
  },
}
</script>
